<template>
  <div class="wap-product-detail">
    <headerBar :title="$t('cha-kan-wu-liu')" @back="back"></headerBar>

    <div class="detail-box">
      <Steps
        direction="vertical"
        :active="logisticsList.length - 1"
        active-color="#1552f0"
        inactive-color="#1552f0"
        finish-icon="checked"
      >
        <Step v-for="(item, i) in logisticsList" :key="i">
          <div>{{ item.LogisticsRoute.Description }}</div>
          <div>{{ item.ExecuteTime }}</div>
        </Step>
      </Steps>
    </div>
  </div>
</template>
<script>
import { Steps, Step } from 'vant'
import { cronList } from '@/api/shop'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Steps,
    Step,
    headerBar
  },
  data() {
    return {
      carUrl: require('@/assets/imgs/icon-car.png'),
      messageUrl: require('@/assets/imgs/icon-message.png'),
      dataId: '',
      detailData: {
        ShippingAddress: {},
        OrderProduct: []
      },
      logisticsList: [],
      logistics: {}
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      let data = sessionStorage.getItem('orderDetail')
      if (data) {
        this.detailData = JSON.parse(data)
        this.logistics = this.detailData.Logistics
        cronList({
          OrderId: this.detailData.ID
        }).then((res) => {
          let data = res.data.Items
          if (data.length > 0) {
            let logisticId = this.detailData.ShowRoute
              ? JSON.parse(this.detailData.ShowRoute)
              : []
            data.forEach((v) => {
              if (logisticId instanceof Array) {
                if (logisticId.indexOf(v.LogisticsRouteId) > -1) {
                  this.logisticsList.push(v)
                }
              } else {
                if (logisticId[v.LogisticsRouteId]) {
                  this.logisticsList.push(v)
                }
              }
            })
          }
        })
      }
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    }
  }
}
</script>